import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { useCallbackOncePerPage } from "src/hooks/useCallbackOncePerPage"

export type FunctionRef = (node?: Element | null) => void

export function useInViewEffectOncePerPage({
  id,
  effect,
  dependencies,
}: {
  id: string
  effect: () => void
  dependencies?: unknown[]
}) {
  const { ref, inView } = useInView()

  const callback = useCallbackOncePerPage({
    id,
    condition: inView,
    effect,
    dependencies,
  })

  useEffect(() => callback(), [callback])

  return ref
}
