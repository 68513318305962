import { GiftCard } from "@medusajs/medusa"
import {
  addItem,
  createCart,
  deleteDiscount,
  removeItem,
  retrieveCart,
  updateCart,
  updateItem,
} from "src/data/medusa/carts"
import { findRegionForCountryCode } from "src/data/medusa/regions"
import { googleTrackGiftCardApplied } from "src/integrations/google/api"
import { ClientCart, requireCartId } from "src/modules/(checkout)/cart/state"
import { trackAddToCart, trackCouponApplied, trackRemoveFromToCart } from "src/tracking/api"
import { reportError } from "src/utilities/error"

/**
 * Retrieves the cart based on the cartId cookie
 */
export async function getOrCreateCart(cartId: string | null, countryCode: string) {
  let cart: ClientCart | null = null

  if (cartId) {
    try {
      cart = await retrieveCart(cartId)
    } catch (error) {
      reportError("Failed to fetch cart", error)
    }
  }

  const region = await findRegionForCountryCode(countryCode)

  if (!region) {
    throw new Error(`Region not found for country code: "${countryCode}"`)
  }

  const region_id = region.id

  if (!cart) {
    cart = await createCart({ region_id })
  }

  if (cart && cart.region_id !== region_id) {
    await updateCart(cart.id, { region_id })
  }

  if (!cart) {
    throw new Error("Error getting or creating cart")
  }

  cart.region = region

  return cart
}

export async function addItemToCart({
  cart,
  countryCode,
  variantId,
  quantity,
}: {
  cart: ClientCart | null
  countryCode: string
  variantId: string
  quantity: number
}) {
  const oldCart = cart || (await getOrCreateCart(null, countryCode))
  const newCart = await addItem({ cartId: oldCart.id, variantId, quantity })
  const newItem = newCart.items.find((item) => item.variant.id === variantId)
  const quantityChange = 1

  if (newItem) {
    trackAddToCart(newCart, newItem, quantityChange)
  }

  return newCart
}

export async function removeItemFromCart({
  oldCart,
  lineId,
}: {
  oldCart: ClientCart
  lineId: string
}) {
  const oldItem = oldCart.items.find((item) => item.id === lineId)
  const newCart = await removeItem({ cartId: oldCart.id, lineId })

  if (oldItem) {
    const quantityChange = oldItem.quantity

    trackRemoveFromToCart(newCart, oldItem, quantityChange)
  }

  return newCart
}

export async function editCartItemQuantity({
  oldCart,
  lineId,
  targetQuantity,
}: {
  oldCart: ClientCart
  lineId: string
  targetQuantity: number
}) {
  const oldItem = oldCart.items.find((item) => item.id === lineId)
  const quantityChange = targetQuantity - (oldItem?.quantity || 0)

  const newCart = await updateItem({ cartId: oldCart.id, lineId, quantity: targetQuantity })
  const newItem = newCart.items.find((item) => item.id === lineId)

  if (newItem) {
    if (quantityChange > 0) {
      trackAddToCart(newCart, newItem, quantityChange)
    } else {
      trackRemoveFromToCart(newCart, newItem, -quantityChange)
    }
  }

  return newCart
}

export async function applyDiscount(code: string) {
  const cartId = requireCartId()

  const newCart = await updateCart(cartId, { discounts: [{ code }] })

  trackCouponApplied(code)

  return newCart
}

export async function removeDiscount(code: string) {
  const cartId = requireCartId()

  return deleteDiscount(cartId, code)
}

export async function applyGiftCard(code: string) {
  const cartId = requireCartId()

  const newCart = await updateCart(cartId, { gift_cards: [{ code }] })

  googleTrackGiftCardApplied(code)

  return newCart
}

export async function removeGiftCard(codeToRemove: string, giftCards: GiftCard[]) {
  const cartId = requireCartId()

  const newCart = await updateCart(cartId, {
    gift_cards: [...giftCards]
      .filter((giftCart) => giftCart.code !== codeToRemove)
      .map((giftCart) => ({ code: giftCart.code })),
  })

  return newCart
}
