import { StorePostCartsCartReq } from "@medusajs/medusa"
import { enrichLineItems } from "src/modules/(checkout)/cart/utils"
import { getPublicMedusaClient, throwMedusaError } from "src/utilities/medusa"

export async function createCart(data = {}) {
  const cart = await getPublicMedusaClient()
    .carts.create(data)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function updateCart(cartId: string, data: StorePostCartsCartReq) {
  const cart = await getPublicMedusaClient()
    .carts.update(cartId, data)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export const retrieveCartWithoutLineItemEnrichment = async (cartId: string) => {
  return getPublicMedusaClient()
    .carts.retrieve(cartId)
    .then(({ cart }) => cart)
    .catch((error) => {
      console.error(error)
      return null
    })
}

export const retrieveCart = async (cartId: string) => {
  const cart = await retrieveCartWithoutLineItemEnrichment(cartId)

  if (cart) {
    await enrichLineItems(cart)
  }

  return cart
}

export async function addItem({
  cartId,
  variantId,
  quantity,
}: {
  cartId: string
  variantId: string
  quantity: number
}) {
  const cart = await getPublicMedusaClient()
    .carts.lineItems.create(cartId, { variant_id: variantId, quantity })
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function updateItem({ cartId, lineId, quantity }: { cartId: string; lineId: string; quantity: number }) {
  const cart = await getPublicMedusaClient()
    .carts.lineItems.update(cartId, lineId, { quantity })
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function removeItem({ cartId, lineId }: { cartId: string; lineId: string }) {
  const cart = await getPublicMedusaClient()
    .carts.lineItems.delete(cartId, lineId)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function deleteDiscount(cartId: string, code: string) {
  const cart = await getPublicMedusaClient()
    .carts.deleteDiscount(cartId, code)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function setShippingMethod({
  cartId,
  shippingMethodId,
}: {
  cartId: string
  shippingMethodId: string
}) {
  const cart = await getPublicMedusaClient()
    .carts.addShippingMethod(cartId, { option_id: shippingMethodId })
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function createPaymentSessions(cartId: string) {
  const cart = await getPublicMedusaClient()
    .carts.createPaymentSessions(cartId)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function setPaymentSession({ cartId, providerId }: { cartId: string; providerId: string }) {
  const cart = await getPublicMedusaClient()
    .carts.setPaymentSession(cartId, { provider_id: providerId })
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}

export async function deletePaymentSession({ cartId, providerId }: { cartId: string; providerId: string }) {
  const cart = await getPublicMedusaClient()
    .carts.deletePaymentSession(cartId, providerId)
    .then(({ cart }) => cart)
    .catch(throwMedusaError)

  await enrichLineItems(cart)

  return cart
}
