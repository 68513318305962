"use client"

import { LineItem, Order } from "@medusajs/medusa"
import { useEffect, useRef } from "react"
import { useEffectOncePerPage } from "src/hooks/useEffectOncePerPage"
import { useInViewEffectOncePerPage } from "src/hooks/useInViewEffectOncePerPage"
import { useIsClient } from "src/hooks/useIsClient"
import {
  googleTrackAddPaymentInfo,
  googleTrackAddShippingInfo,
  googleTrackAddToCart,
  googleTrackBeginCheckout,
  googleTrackCouponApplied,
  googleTrackEmbedView,
  googleTrackPageView,
  googleTrackPurchase,
  googleTrackRemoveFromCart,
  googleTrackSearch,
  googleTrackSelectItem,
  googleTrackSelectPromotion,
  googleTrackViewCart,
  googleTrackViewItem,
  googleTrackViewItemList,
  googleTrackViewPromotion,
} from "src/integrations/google/api"
import {
  mapCartOrderToGoogleAddPaymentInfoEvent,
  mapCartToGoogleAddShippingInfoEvent,
  mapCartToGoogleBeginCheckoutEvent,
  mapCartToGoogleViewCartEvent,
  mapLineItemToGoogleAddToCartEvent,
  mapOrderToGooglePurchaseEvent,
  mapProductToGoogleItemData,
  mapProductToGoogleRemoveFromCartEvent,
  mapProductToGoogleViewItemEvent,
  mapProductToGoogleViewPromotionItemData,
} from "src/integrations/google/mapping"
import { GoogleEmbedEvent } from "src/integrations/google/types"
import {
  klaviyoTrackAddedToCart,
  klaviyoTrackStartedCheckout,
  klaviyoTrackViewedProduct,
} from "src/integrations/klaviyo/api"
import {
  mapCartChangeToKlaviyoAddedToCartEvent,
  mapCartToKlaviyoStartedCheckoutEvent,
  mapProductToKlaviyoViewedProductEvent,
} from "src/integrations/klaviyo/mapping"
import { mergeContactDataFromObjects } from "src/integrations/lib/mergeState"
import { ClientCart, useCart } from "src/modules/(checkout)/cart/state"
import {
  mapCartChangeToTrackingLineItem,
  mapCartToTrackingCart,
  mapDetailProductToTrackingProduct,
  mapListProductToTrackingProduct,
  mapOrderToTrackingOrder,
} from "src/tracking/mapping"
import { DetailProductData, DetailVariantData, ListProductData } from "src/types/global"
import { createCartHash } from "src/utilities/cart"
import { isBrowser } from "src/utilities/env"
import { useWindowLocationPathname } from "src/utilities/useWindowLocationPathname"

function trackPageView({ referrer }: { referrer: string }) {
  googleTrackPageView({ referrer })
}

// Use a non-hook variable to avoid duplicate page view tracking caused by local
// React Strict Mode.
let previousPathname = ""
let previousHref = ""

export function useTrackPageViews() {
  const pathname = useWindowLocationPathname()

  if (!isBrowser()) {
    return
  }

  // ! Track page views outside of an effect so they are tracked before anything
  // else that could be called in an effect.
  if (!previousPathname) {
    const referrer = document.referrer

    trackPageView({ referrer })
  } else if (pathname !== previousPathname) {
    const referrer = previousHref

    trackPageView({ referrer })
  }

  previousPathname = pathname
  previousHref = window.location.href
}

export function useTrackViewItemList(listId: string, products: ListProductData[]) {
  return useInViewEffectOncePerPage({
    id: `view_item_list | ${listId}`,
    effect: () => {
      const trackingProducts = products.map(mapListProductToTrackingProduct)

      googleTrackViewItemList({
        item_list_id: listId,
        items: trackingProducts.map(mapProductToGoogleItemData),
      })
    },
  })
}

export function trackSearch(input: string) {
  googleTrackSearch({ search_term: input })
}

export function trackProductLinkClick(listId: string, product: ListProductData) {
  const trackingProduct = mapListProductToTrackingProduct(product)
  const item = mapProductToGoogleItemData(trackingProduct)

  googleTrackSelectItem({ item_list_id: listId, items: [item] })

  if (trackingProduct.promotionId) {
    const promotionItem = mapProductToGoogleViewPromotionItemData(trackingProduct)

    googleTrackSelectPromotion({ items: [promotionItem] })
  }
}

export function trackViewEmbed(data: GoogleEmbedEvent) {
  googleTrackEmbedView(data)
}

function trackViewPromotion(product: ListProductData) {
  const trackingProduct = mapListProductToTrackingProduct(product)

  if (trackingProduct.promotionId) {
    const promotionItem = mapProductToGoogleViewPromotionItemData(trackingProduct)

    googleTrackViewPromotion({ items: [promotionItem] })
  }
}

export function useTrackViewPromotion(product: ListProductData) {
  return useInViewEffectOncePerPage({
    id: `useTrackViewPromotion | ${product.id}`,
    effect: () => trackViewPromotion(product),
  })
}

function trackViewProduct(product: DetailProductData, variant: DetailVariantData) {
  const trackingProduct = mapDetailProductToTrackingProduct(product, variant)

  googleTrackViewItem(mapProductToGoogleViewItemEvent(trackingProduct))
  klaviyoTrackViewedProduct(mapProductToKlaviyoViewedProductEvent(trackingProduct))

  if (trackingProduct.promotionId) {
    const promotionItem = mapProductToGoogleViewPromotionItemData(trackingProduct)

    googleTrackViewPromotion({ items: [promotionItem] })
  }
}

export function useTrackViewProduct(product: DetailProductData, variant: DetailVariantData | undefined) {
  const isClient = useIsClient()

  useEffectOncePerPage({
    id: `useTrackViewProduct | ${variant?.id}`,
    condition: isClient && !!variant,
    effect: () => trackViewProduct(product, variant!),
  })
}

export function trackAddToCart(cart: ClientCart, item: LineItem, quantityChange: number) {
  const trackingCart = mapCartToTrackingCart(cart)
  const trackingLineItem = mapCartChangeToTrackingLineItem(cart, item, quantityChange)

  googleTrackAddToCart(mapLineItemToGoogleAddToCartEvent(trackingLineItem))
  klaviyoTrackAddedToCart(mapCartChangeToKlaviyoAddedToCartEvent(trackingCart, trackingLineItem))
}

export function trackRemoveFromToCart(cart: ClientCart, item: LineItem, quantityChange: number) {
  const trackingCart = mapCartToTrackingCart(cart)
  const trackingLineItem = mapCartChangeToTrackingLineItem(cart, item, quantityChange)

  googleTrackRemoveFromCart(mapProductToGoogleRemoveFromCartEvent(trackingLineItem))
  klaviyoTrackAddedToCart(
    mapCartChangeToKlaviyoAddedToCartEvent(trackingCart, {
      ...trackingLineItem,
      quantity: -quantityChange,
    }),
  )
}

function trackViewCart(cart: ClientCart) {
  const trackingCart = mapCartToTrackingCart(cart)

  googleTrackViewCart(mapCartToGoogleViewCartEvent(trackingCart))
}

export function useTrackViewCart() {
  const cart = useCart()
  const cartHashRef = useRef<string>()

  const cartHash = createCartHash(cart)

  useEffect(() => {
    if (!cart || cartHash === cartHashRef.current) {
      return
    }

    trackViewCart(cart)

    cartHashRef.current = cartHash
  }, [cart, cartHash])
}

export function trackCouponApplied(code: string) {
  googleTrackCouponApplied(code)
}

export function trackBeginCheckout(cart: ClientCart) {
  const trackingCart = mapCartToTrackingCart(cart)

  googleTrackBeginCheckout(mapCartToGoogleBeginCheckoutEvent(trackingCart))
  klaviyoTrackStartedCheckout(mapCartToKlaviyoStartedCheckoutEvent(trackingCart))
}

export function trackAddShippingInfo(cart: ClientCart) {
  const trackingCart = mapCartToTrackingCart(cart)

  googleTrackAddShippingInfo(mapCartToGoogleAddShippingInfoEvent(trackingCart))
}

export function trackAddPaymentInfo(cart: ClientCart) {
  const trackingCart = mapCartToTrackingCart(cart)

  googleTrackAddPaymentInfo(mapCartOrderToGoogleAddPaymentInfoEvent(trackingCart))
}

export function isOrderSucecssful(order: Order | undefined) {
  if (!order) {
    return false
  }

  const status = order.status

  return status === "pending" || status === "completed"
}

async function trackPurchase(order: Order) {
  const trackingOrder = mapOrderToTrackingOrder(order)
  const event = mapOrderToGooglePurchaseEvent(trackingOrder)

  googleTrackPurchase(event)
}

export function useTrackPurchase(order: Order) {
  if (isBrowser()) {
    mergeContactDataFromObjects({ order })
  }

  useEffectOncePerPage({
    id: "track_purchase_" + order.id,
    condition: isOrderSucecssful(order),
    effect: () => {
      trackPurchase(order)
    },
  })
}
