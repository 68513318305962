import Medusa from "@medusajs/medusa-js"

let publicClient: Medusa
let privateClient: Medusa

export function getPublicMedusaClient() {
  if (!publicClient) {
    const backendOrigin = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN
    const publicApiKey = process.env.NEXT_PUBLIC_MEDUSA_API_KEY

    if (!backendOrigin) {
      throw new Error("Please set NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN")
    }
    if (!publicApiKey) {
      throw new Error("Please set NEXT_PUBLIC_MEDUSA_API_KEY")
    }

    publicClient = new Medusa({
      publishableApiKey: publicApiKey,
      baseUrl: backendOrigin,
      maxRetries: 3,
    })
  }

  return publicClient
}

export function getPrivateMedusaClient() {
  if (!privateClient) {
    const backendOrigin = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN
    const privateApiKey = process.env.ADMIN_API_KEY

    if (!backendOrigin) {
      throw new Error("Please set NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN")
    }
    if (!privateApiKey) {
      throw new Error("Please set ADMIN_API_KEY")
    }

    privateClient = new Medusa({
      apiKey: privateApiKey,
      baseUrl: backendOrigin,
      maxRetries: 3,
    })
  }

  return privateClient
}

export function throwMedusaError(error: any): never {
  if (error.response) {
    const message =
      "Request failed: " +
      JSON.stringify(
        {
          method: error.config?.method,
          url: error.config?.url,
          requestData: error.config?.data,
          requestHeaders: error.config?.headers,
          status: error.response.status,
          responseData: error.response.data,
        },
        null,
        2,
      )

    throw new Error(message)
  }

  if (error.request) {
    const message =
      "Request failed without response: " +
      JSON.stringify(
        {
          method: error.config?.method,
          url: error.config?.url,
          data: error.config?.data,
          headers: error.config?.headers,
        },
        null,
        2,
      )

    throw new Error(message)
  }

  throw new Error("Unknown error: " + String(error))
}

export function throwMedusaErrorIfFound(error: any) {
  if (error.response?.status === 404) {
    return null
  }
  throwMedusaError(error)
}
