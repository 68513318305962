import { StorePostAuthReq } from "@medusajs/medusa"
import { getPublicMedusaClient, throwMedusaError } from "src/utilities/medusa"

export async function authenticate(credentials: StorePostAuthReq) {
  return getPublicMedusaClient()
    .auth.authenticate(credentials)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function getSession() {
  return getPublicMedusaClient()
    .auth.getSession()
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}
