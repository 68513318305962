const storefrontOrigin = process.env.NEXT_PUBLIC_STOREFRONT_ORIGIN || ""

function mapDomains(src: string) {
  return src
    .replace(/^\//, storefrontOrigin + "/")
    .replace("https://flowers-and-me.shop/", "https://flowers-and-me.twic.pics/shop/")
    .replace("https://storefront-prod-wcl6.onrender.com/", "https://flowers-and-me.twic.pics/prod-shop/")
    .replace("https://storefront-demo-2e44.onrender.com/", "https://flowers-and-me.twic.pics/demo-shop/")
    .replace("https://minio-server-prod.onrender.com/medusa-public-files/", "https://flowers-and-me.twic.pics/files/")
    .replace(
      "https://minio-server-a47q.onrender.com/medusa-public-files/",
      "https://flowers-and-me.twic.pics/demo-files/",
    )
    .replace("https://i.ytimg.com/", "https://flowers-and-me.twic.pics/yt/")
}

export type TwicPicsOptions = {
  w?: number
  h?: number
  fit?: "cover" | "contain"
}

export function toImageCdnUrl(src: string, options?: TwicPicsOptions) {
  if (src.startsWith("data:")) {
    return src
  }
  if (src.startsWith("https://dummyimage.com")) {
    return src
  }

  const twicPicsUrl = mapDomains(src)

  // https://www.twicpics.com/docs/reference/transformations
  const transformations: string[] = []

  if (options?.w || options?.h) {
    const tp_resize = options?.fit === "contain" ? "contain" : "cover"
    const tp_width = options?.w || "-"
    const tp_height = options?.h || "-"

    transformations.push(`${tp_resize}=${tp_width}x${tp_height}`)
    transformations.push("focus=auto")
  }

  return [twicPicsUrl, transformations.join("/")].filter(Boolean).join("?twic=v1/")
}

export function toProductImageCdnUrl(src: string) {
  return toImageCdnUrl(src, { w: 1024, h: 1024, fit: "contain" })
}
