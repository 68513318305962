import { Product, Region } from "@medusajs/medusa"
import { DETAIL_PRODUCT_RELATIONS } from "src/data/medusa/config"
import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"
import { SortOption } from "src/modules/(landing)/common/types"
import { DetailProductData } from "src/types/global"
import { getPublicMedusaClient, throwMedusaError } from "src/utilities/medusa"

export const getProductsByIds = async ({ ids, regionId }: { ids: string[]; regionId: string }) => {
  return getPublicMedusaClient()
    .products.list({ id: ids, region_id: regionId })
    .then(({ products }) => products)
    .catch(throwMedusaError)
}

export const retrievePricedProductById = async ({ id, regionId }: { id: string; regionId: string }) => {
  return getPublicMedusaClient()
    .products.retrieve(`${id}?region_id=${regionId}&expand=${DETAIL_PRODUCT_RELATIONS}`)
    .then(({ product }) => product)
    .catch((error) => {
      console.error(error)
      return null
    })
}

export const getProductByHandle = async (handle: string) => {
  const result = await getPublicMedusaClient().products.list({ handle })

  return result.products[0]
}

export type GetProductListParams = {
  region: Region
  collection_id?: string[]
  category_id?: string[]
  tags?: string[]
  is_giftcard?: boolean
  limit?: number
  page: number
  expand?: string
}

export const getProductList = async ({
  region,
  collection_id,
  category_id,
  tags,
  is_giftcard,
  limit = 12,
  page = 1,
  expand,
}: GetProductListParams) => {
  const offset = (page - 1) * limit

  return getPublicMedusaClient()
    .products.list({
      collection_id,
      category_id,
      tags,
      is_giftcard,
      offset,
      limit,
      region_id: region.id,
      currency_code: region.currency_code,
      expand,
    })
    .catch(throwMedusaError)
}

export async function listDetailProducts() {
  const countryCode = DEFAULT_COUNTRY_CODE
  const regionsResponse = await getPublicMedusaClient().regions.list({ country_code: countryCode })
  const region = regionsResponse.regions[0]

  if (!region) {
    throw new Error(`No region found for country code ${countryCode}`)
  }

  const products: DetailProductData[] = []

  while (true) {
    const response = await getPublicMedusaClient()
      .products.list({
        offset: products.length,
        limit: 100,
        region_id: region.id,
        currency_code: countryCode,
        expand: DETAIL_PRODUCT_RELATIONS,
      })
      .catch(throwMedusaError)

    products.push(...response.products)

    if (response.products.length < 100) {
      break
    }
  }

  return products
}
