import { uniq } from "lodash"
import { localizePath, routes } from "src/config/routes"
import { META_DATA } from "src/metadata/constants"
import { TrackingCart, TrackingLineItem, TrackingProduct } from "src/tracking/types"
import { toProductImageCdnUrl } from "src/utilities/images"
import { roundPrice } from "src/utilities/price"
import {
  KlaviyoAddedToCartEvent,
  KlaviyoLineItemData,
  KlaviyoStartedCheckoutEvent,
  KlaviyoViewedItemEvent,
  KlaviyoViewedProductEvent,
} from "./types"

function formatUrl(path: string) {
  return META_DATA.origin + localizePath(path)
}

export function mapProductToKlaviyoViewedProductEvent(product: TrackingProduct): KlaviyoViewedProductEvent {
  return {
    ProductID: product.id,
    ProductName: product.name + (product.variantName ? ` (${product.variantName})` : ""),
    SKU: product.sku || "unknown",
    Categories: product.categories || [],
    ImageURL: toProductImageCdnUrl(product.image),
    URL: formatUrl(routes.format_product(product.slug)),
    Brand: "elvent",
    Price: product.netOfferPrice,
    CompareAtPrice: product.netFullPrice,
  }
}

export function mapKlaviyoViewedProductEventToKlaviyoViewedItemEvent(
  event: KlaviyoViewedProductEvent,
): KlaviyoViewedItemEvent {
  return {
    Title: event.ProductName,
    ItemId: event.ProductID,
    Categories: event.Categories,
    ImageUrl: event.ImageURL,
    Url: event.URL,
    Metadata: {
      Brand: event.Brand,
      Price: event.Price,
      CompareAtPrice: event.CompareAtPrice,
    },
  }
}

function mapLineItemToKlaviyoLineItemData(product: TrackingLineItem): KlaviyoLineItemData {
  const klaviyoProduct = mapProductToKlaviyoViewedProductEvent(product)

  return {
    ProductID: klaviyoProduct.ProductID,
    SKU: klaviyoProduct.SKU,
    ProductName: klaviyoProduct.ProductName,
    Quantity: product.quantity,
    ItemPrice: klaviyoProduct.Price,
    RowTotal: roundPrice(klaviyoProduct.Price * product.quantity),
    ProductURL: klaviyoProduct.URL,
    ImageURL: klaviyoProduct.ImageURL,
    ProductCategories: klaviyoProduct.Categories,
  }
}

export function mapCartChangeToKlaviyoAddedToCartEvent(
  cart: TrackingCart,
  lineItem: TrackingLineItem,
): KlaviyoAddedToCartEvent {
  return {
    $value: lineItem.netOfferPrice * lineItem.quantity,
    AddedItemProductName: lineItem.name,
    AddedItemProductID: lineItem.id.toString(),
    AddedItemSKU: lineItem.sku || "unknown",
    AddedItemCategories: lineItem.categories || [],
    AddedItemImageURL: toProductImageCdnUrl(lineItem.image),
    AddedItemURL: formatUrl(routes.format_product(lineItem.slug)),
    AddedItemPrice: lineItem.netOfferPrice,
    AddedItemQuantity: lineItem.quantity,
    ItemNames: cart.items.map((item) => item.name),
    CheckoutURL: formatUrl(routes.checkout),
    Items: cart.items.map(mapLineItemToKlaviyoLineItemData),
  }
}

export function mapCartToKlaviyoStartedCheckoutEvent(cart: TrackingCart): KlaviyoStartedCheckoutEvent {
  return {
    $event_id: `${cart.id}_${Date.now()}`,
    $value: cart.netValue,
    ItemNames: cart.items.map((item) => item.name),
    CheckoutURL: formatUrl(routes.checkout),
    Categories: uniq(cart.items.flatMap((item) => item.categories || [])),
    Items: cart.items.map(mapLineItemToKlaviyoLineItemData),
  }
}
