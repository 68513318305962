import { LineItem, Order } from "@medusajs/medusa"
import { ClientCart } from "src/modules/(checkout)/cart/state"
import { TrackingCart, TrackingLineItem, TrackingOrder, TrackingProduct } from "src/tracking/types"
import { DetailProductData, DetailVariantData, ListProductData } from "src/types/global"
import { roundPrice } from "src/utilities/price"

const emptyEmage = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

// TODO this must be based on the region
export function deductGermanTax<T extends number | undefined>(price: T): T {
  if (price) {
    // prices are maintained with German tax (19%) included
    return (price / 1.19) as T
  }
  return undefined as T
}

function mapPrice(price: number): number {
  return roundPrice(price / 100)
}

export function mapListProductToTrackingProduct(product: ListProductData): TrackingProduct {
  const grossOfferPrice = product.prices.calculatedPrice || 0
  const grossRegularPrice = product.prices.originalPrice || 0
  const grossDiscount = grossRegularPrice - grossOfferPrice
  const netOfferPrice = deductGermanTax(grossOfferPrice)
  const netRegularPrice = deductGermanTax(grossRegularPrice)
  const netDiscount = netRegularPrice - netOfferPrice
  const promotionId = (product.prices.type !== "default" && product.prices.type) || undefined

  return {
    id: product.id.toString(),
    sku: undefined,
    slug: product.handle,
    name: product.title,
    variantName: undefined,
    image: product.thumbnail,
    categories: product.categoryNames,
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossFullPrice: mapPrice(grossRegularPrice),
    grossDiscount: mapPrice(grossDiscount),
    netOfferPrice: mapPrice(netOfferPrice),
    netFullPrice: mapPrice(netRegularPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: product.prices.currencyCode.toUpperCase(),
    promotionId: promotionId,
  }
}

export function mapDetailProductToTrackingProduct(
  product: DetailProductData,
  variant: DetailVariantData,
): TrackingProduct {
  const grossOfferPrice = variant.calculated_price || 0
  const grossRegularPrice = variant.original_price || 0
  const grossDiscount = grossRegularPrice - grossOfferPrice
  const netOfferPrice = deductGermanTax(grossOfferPrice)
  const netRegularPrice = deductGermanTax(grossRegularPrice)
  const netDiscount = netRegularPrice - netOfferPrice
  const price = variant.prices.find((price) => price.amount === variant.calculated_price)
  const promotionId = (variant.calculated_price_type !== "default" && variant.calculated_price_type) || undefined

  if (!variant.id) {
    throw new Error("Variant has no id")
  }
  if (!product.handle) {
    throw new Error("Product has no handle")
  }
  if (!product.title) {
    throw new Error("Product has no title")
  }
  if (!product.thumbnail) {
    throw new Error("Product has no thumbnail")
  }

  return {
    id: variant.id.toString(),
    sku: variant.sku || undefined,
    slug: product.handle,
    name: product.title,
    variantName: variant.title || undefined,
    image: product.thumbnail,
    categories: (product.categories || []).map((category) => category.name),
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossFullPrice: mapPrice(grossRegularPrice),
    grossDiscount: mapPrice(grossDiscount),
    netOfferPrice: mapPrice(netOfferPrice),
    netFullPrice: mapPrice(netRegularPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: price?.currency_code.toUpperCase(),
    promotionId: promotionId,
  }
}

export function mapCartChangeToTrackingLineItem(
  cart: Pick<ClientCart, "region" | "items" | "discounts">,
  item: LineItem,
  quantityChange?: number,
): TrackingLineItem {
  // {
  //   "discount_total": 1311,
  //   "original_tax_total": 1245,
  //   "original_total": 7800,
  //   "quantity": 2,
  //   "subtotal": 6555,
  //   "tax_total": 996,
  //   "total": 6240,
  //   "unit_price": 3900
  // }

  const quantity = quantityChange || item.quantity
  const grossOfferPrice = (item.total || 0) / quantity
  const grossRegularPrice = (item.original_total || 0) / quantity
  const grossDiscount = grossRegularPrice - grossOfferPrice
  const netOfferPrice = deductGermanTax(grossOfferPrice)
  const netRegularPrice = deductGermanTax(grossRegularPrice)
  const netDiscount = netRegularPrice - netOfferPrice

  const index = cart.items.findIndex((cartItem) => cartItem.id === item.id)

  const discount_id = item.adjustments[0]?.discount_id
  const disountCode = cart.discounts.find((discount) => discount.id === discount_id)?.code

  if (!item.variant.product.handle) {
    throw new Error("Product has no handle")
  }

  return {
    id: item.id.toString(),
    sku: item.variant.id.toString(),
    slug: item.variant.product.handle,
    name: item.variant.product.title,
    variantName: item.variant.title,
    image: item.thumbnail || emptyEmage,
    categories: [], // TODO add categories
    grossOfferPrice: mapPrice(grossOfferPrice),
    grossFullPrice: mapPrice(grossRegularPrice),
    grossDiscount: mapPrice(grossDiscount),
    netOfferPrice: mapPrice(netOfferPrice),
    netFullPrice: mapPrice(netRegularPrice),
    netDiscount: mapPrice(netDiscount),
    currencyCode: cart.region.currency_code.toUpperCase(),
    promotionId: undefined, // TODO add promotionId
    quantity: quantity,
    index: index,
  }
}

function mapDiscounts(discounts: ClientCart["discounts"] | undefined) {
  return (discounts || []).map((discounts) => discounts.code.toLowerCase()).join(", ")
}

function mapCartItems(cart: Pick<ClientCart, "region" | "items" | "discounts">) {
  return cart.items.map((item) => mapCartChangeToTrackingLineItem(cart, item))
}

export function mapCartToTrackingCart(cart: ClientCart): TrackingCart {
  const grossValue = cart.total || 0
  const grossShipping = cart.shipping_total || 0
  const netTotal = cart.subtotal || 0
  const netShipping = grossShipping - (cart.shipping_tax_total || 0)
  const totalTax = cart.tax_total || 0

  return {
    id: cart.id,
    grossValue: mapPrice(grossValue),
    grossShipping: mapPrice(grossShipping),
    netValue: mapPrice(netTotal),
    netShipping: mapPrice(netShipping),
    totalTax: mapPrice(totalTax),
    currencyCode: cart.region.currency_code.toUpperCase(),
    discountCode: mapDiscounts(cart.discounts),
    items: cart.items.map((item) => mapCartChangeToTrackingLineItem(cart, item)),
    shippingMethod: cart.shipping_methods[0]?.shipping_option.name,
    paymentMethod: cart.payment_session?.provider_id,
  }
}

export function mapOrderToTrackingOrder(order: Order): TrackingOrder {
  const grossValue = order.total || 0
  const grossShipping = order.shipping_total || 0
  const netValue = order.subtotal || 0
  const netShipping = grossShipping - (order.shipping_tax_total || 0)
  const totalTax = order.tax_total || 0

  return {
    transactionId: order.id,
    grossValue: mapPrice(grossValue),
    grossShipping: mapPrice(grossShipping),
    netValue: mapPrice(netValue),
    netShipping: mapPrice(netShipping),
    totalTax: mapPrice(totalTax),
    currencyCode: order.currency_code.toUpperCase(),
    discountCode: mapDiscounts(order.discounts),
    items: mapCartItems(order),
  }
}
