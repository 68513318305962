import { defaultStoreProductsRelations } from "@medusajs/medusa"

// https://github.com/medusajs/medusa/blob/v1.20.10/packages/medusa/src/api/routes/store/products/index.ts#L58
export const DETAIL_PRODUCT_RELATIONS = [
  "categories",
  "collection",
  "images",
  "options.values",
  "options",
  "variants.options",
  "variants.prices",
  "variants",
].join(",")
