import { Region } from "@medusajs/medusa"
import { devDebug } from "src/utilities/debug"
import { getPublicMedusaClient, throwMedusaError } from "src/utilities/medusa"

const DEFAULT_COUNTRY_CODE = "de"

export const listRegions = async () => {
  return getPublicMedusaClient()
    .regions.list()
    .then(({ regions }) => regions)
    .catch(throwMedusaError)
}

export const listCountryCodes = async () => {
  const regions = await listRegions()

  return regions.flatMap((region) => {
    return region.countries.map((country) => {
      return country.iso_2
    })
  })
}

export const retrieveRegion = async (id: string) => {
  return getPublicMedusaClient()
    .regions.retrieve(id)
    .then(({ region }) => region)
    .catch(throwMedusaError)
}

const regionMap = new Map<string, Region>()

export const findRegionForCountryCode = async (countryCode: string) => {
  if (regionMap.has(countryCode)) {
    return regionMap.get(countryCode)!
  }

  const regions = await listRegions()

  for (const region of regions) {
    for (const country of region.countries) {
      regionMap.set(country.iso_2, region)
    }
  }

  const region = countryCode ? regionMap.get(countryCode) : regionMap.get(DEFAULT_COUNTRY_CODE)

  if (!region) {
    throw new Error(`Region for country code ${countryCode} not found`)
  }

  return region
}
