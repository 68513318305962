import { useIsClient } from "src/hooks/useIsClient"
import { localStoragePersist } from "src/utilities/localStoragePersist"
import { create as createStore } from "zustand"

type State = {
  gtmDebug?: boolean
}

const defaultState: State = {}

const store = createStore(localStoragePersist(() => defaultState, { name: "debug" }))

export function useGtmDebug() {
  const isClient = useIsClient()
  const value = store((state) => state.gtmDebug)

  return isClient && value
}

export function setGtmDebug(gtmDebug: boolean) {
  store.setState({ gtmDebug })
}

export function getGtmDebug() {
  return store.getState().gtmDebug
}
