import { Cart, LineItem, Order } from "@medusajs/medusa"
import { omit } from "lodash"
import { getProductsByIds } from "src/data/medusa/products"
import { getCountryCodeOrThrow } from "src/localization/state"
import { ClientCart } from "src/modules/(checkout)/cart/state"
import { formatAmount, roundPrice } from "src/utilities/price"

export async function enrichLineItems<C extends Pick<Cart, "items" | "region_id">>(
  cart: C,
): Promise<Omit<LineItem, "beforeInsert" | "beforeUpdate" | "afterUpdateOrLoad">[] | undefined> {
  // Prepare query parameters
  const queryParams = {
    ids: cart.items.map((lineItem) => lineItem.variant.product_id),
    regionId: cart.region_id,
  }

  // Fetch products by their IDs
  const products = await getProductsByIds(queryParams)

  // If there are no line items or products, return an empty array
  if (!cart.items?.length || !products) {
    return []
  }

  // Enrich line items with product and variant information

  const enrichedItems = cart.items.map((item) => {
    const product = products.find((product) => product.id === item.variant.product_id)
    const variant = product?.variants.find((variant) => variant.id === item.variant_id)

    if (!product || !variant) {
      // If product or variant is not found, return the original item
      return item
    }

    // If product and variant are found, enrich the item
    return {
      ...item,
      variant: {
        ...variant,
        product: omit(product, "variants"),
      },
    }
  }) as LineItem[]

  cart.items = enrichedItems as LineItem[]
}

export function compareLineItems(a: LineItem, b: LineItem) {
  return a.created_at > b.created_at ? 1 : -1
}

export function formatShippingTotal(data: ClientCart | Order) {
  const countryCode = getCountryCodeOrThrow()
  const shippingAmount = data.shipping_methods.reduce((sum, method) => sum + (method.total || 0), 0)
  const shippingIsFree = !shippingAmount
  const shippingTotal = formatAmount({
    amount: shippingAmount,
    region: data.region,
    countryCode,
  })

  return {
    shippingTotal,
    shippingIsFree,
  }
}

export function formatTotals(data: ClientCart | Order) {
  const countryCode = getCountryCodeOrThrow()

  const subTotal = formatAmount({
    amount: data.items.reduce((sum, item) => sum + (item.original_total || 0), 0),
    region: data.region,
    countryCode,
  })
  const discountTotal = formatAmount({
    amount: data.items.reduce((sum, item) => sum + roundPrice((item.original_total || 0) - (item.total || 0)), 0),
    region: data.region,
    countryCode,
  })
  const { shippingTotal, shippingIsFree } = formatShippingTotal(data)
  const giftCardTotal = formatAmount({
    amount: data.gift_card_total || 0,
    region: data.region,
    countryCode,
  })
  const taxTotal = formatAmount({
    amount: data.tax_total || 0,
    region: data.region,
    countryCode,
  })
  const total = formatAmount({
    amount: data.total || 0,
    region: data.region,
    countryCode,
  })

  return {
    subTotal,
    discountTotal,
    shippingTotal,
    shippingIsFree,
    giftCardTotal,
    taxTotal,
    total,
  }
}
