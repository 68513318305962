import { AddressPayload } from "@medusajs/medusa"
import { updateUserData } from "src/integrations/lib/userData"
import { localStoragePersist } from "src/utilities/localStoragePersist"
import { create as createStore } from "zustand"

export type ContactData = {
  email: string | null
  phoneNormalized: string | null
  address: AddressPayload | null
}

const defaultState: ContactData = {
  email: null,
  phoneNormalized: null,
  address: null,
}

export const contactStore = createStore(localStoragePersist(() => defaultState, { name: "contact" }))

export function mergeContactData(contactData: Partial<ContactData>) {
  contactStore.setState(contactData)
  updateUserData()
}

export function getContactData() {
  return contactStore.getState()
}
